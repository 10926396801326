import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';

const SEO = ({ page }) => (
  <StaticQuery
    query={graphql`
      query seoQuery {
        text: site {
          siteMetadata {
            title 
            description
          }
        }
        logo: file(relativePath: {eq: "main_logo.png"}) {
          childImageSharp {
            fluid(maxWidth: 400) {
              src
              sizes
              srcSet
              aspectRatio
            }
          }
        }
      }
    `}
    render={data => (
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
        title={`${data.text.siteMetadata.title} | ${page}`}
        meta={[
          {
            name: 'description',
            content: data.text.siteMetadata.description,
          },
          {
            property: 'og:image',
            content: data.logo.childImageSharp.fluid.src,
          },
        ]}
      />
    )}
  />
);

SEO.propTypes = {
  page: PropTypes.string.isRequired,
};


export default SEO;
