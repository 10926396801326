/*eslint-disable */ 

import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { StaticQuery, graphql, withPrefix } from 'gatsby';
import InstructionModalWrapper from '../../InstructionWrapperModal';
import StyledSignupInstructions from './style';


const SignupInstructions = ({ team, isUpload }) => (
  <StaticQuery
    query={graphql`
      query instructionImages {
        bg: file(relativePath: {eq: "signup/topsection_panel_bg.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 1274) {
              src
              sizes
              srcSet
              srcSetWebp
              aspectRatio
            }
          }
        }
        arrekzLogo: file(relativePath: {eq: "arrekz_text_lockup.png"}) {
          childImageSharp {
            fluid(maxWidth: 562) {
              src
              sizes
              srcSet
              srcSetWebp
              aspectRatio
            }
          }
        }
        arekkzUpload: file(relativePath: {eq: "arekkz_new.png"}) {
          childImageSharp {
            fluid(maxWidth: 562) {
              src
              sizes
              srcSet
              srcSetWebp
              aspectRatio
            }
          }
        }
        oxboxLogo: file(relativePath: {eq: "oxbox_text_lockup.png"}) {
          childImageSharp {
            fluid(maxWidth: 562) {
              src
              sizes
              srcSet
              srcSetWebp
              aspectRatio
            }
          }
        }
        bgBottom: file(relativePath: {eq: "signup/orange_bg.png"}) {
          childImageSharp {
            fluid(maxWidth: 1274) {
              src
              sizes
              srcSet
              srcSetWebp
              aspectRatio
            }
          }
        }
        step1: file(relativePath: {eq: "signup/step1_icon.png"}) {
          childImageSharp {
            fluid(maxWidth: 156) {
              src
              sizes
              srcSet
              srcSetWebp
              aspectRatio
            }
          }
        }
        step2: file(relativePath: {eq: "signup/step2_icon.png"}) {
          childImageSharp {
            fluid(maxWidth: 156) {
              src
              sizes
              srcSet
              srcSetWebp
              aspectRatio
            }
          }
        }
        step3: file(relativePath: {eq: "signup/step3_icon.png"}) {
          childImageSharp {
            fluid(maxWidth: 156) {
              src
              sizes
              srcSet
              srcSetWebp
              aspectRatio
            }
          }
        }
        step4: file(relativePath: {eq: "signup/step4_icon.png"}) {
          childImageSharp {
            fluid(maxWidth: 156) {
              src
              sizes
              srcSet
              srcSetWebp
              aspectRatio
            }
          }
        }
      }
    `}
    render={data => (
      <StyledSignupInstructions>
        <div className="overview">
          <Img
            className="overview-bg"
            fluid={data.bg.childImageSharp.fluid}
          />
          {team === 'Arekkz' && !isUpload && (
            <Img
              className="logo"
              fluid={data.arrekzLogo.childImageSharp.fluid}
            />
          )}
          {team === 'OutsideXbox' && !isUpload && (
            <Img
              className="logo"
              fluid={data.oxboxLogo.childImageSharp.fluid}
            />
          )}
          {isUpload && (
            <Img
              className="logo"
              fluid={data.arekkzUpload.childImageSharp.fluid}
            />
          )}
          {!isUpload && (
            <h4>
              To win the chance to play alongside
              {' '}
              {team}
              , please make sure to follow the below steps
            </h4>
          )}
          {isUpload && (
            <h4 className="upload-heading">
              Make sure to capture your best clips, upload to YouTube using
              {' '}
              <span>#enterthedarkzone</span>
              {' '}
              and be in for a chance to win
            </h4>
          )}
          <div className="arrow-down" />
        </div>
        { !isUpload && (
          <div className="instructions-wrapper">
            <Img
              className="overview-bg-bottom"
              fluid={data.bgBottom.childImageSharp.fluid}
            />
            <div className="instruction">
              <Img
                className="icon"
                fluid={data.step2.childImageSharp.fluid}
              />
              <h4>Step 1</h4>
              <h3>Team Up</h3>
              {team === 'Arekkz' ? (
                <p>Preorder and join Arekkz by signing up here</p>
              ) : (
                <p>Preorder on Xbox One and join the team by signing up here</p>
              )}
              <a href="http://ubi.li/zkqv5" target="_blank" rel="noopener noreferrer">
                <button type="button" className="pre-order-btn">PreOrder</button>
              </a>
            </div>
            <div className="instruction">
              <Img
                className="icon"
                fluid={data.step3.childImageSharp.fluid}
              />
              <h4>Step 2</h4>
              <h3>Tune In</h3>
              <p>Tune into the Livestream on Feb 8th at:</p>
              {team === 'Arekkz' ? (
                <a href="https://www.twitch.tv/arekkzgaming" target="blank">twitch.tv/arekkzgaming</a>
              ) : (
                <a href="https://www.youtube.com/watch?v=zLHssjUOAz8" target="blank">youtube.com/outsidexbox</a>
              )}
            </div>
            <div className="instruction">
              <Img
                className="icon"
                fluid={data.step4.childImageSharp.fluid}
              />
              <h4>Step 3</h4>
              <h3>Prizes!</h3>
              <p>Get involved and win prizes with The Division 2</p>
            </div>
          </div>
        )
        }
        {isUpload && (
          <div className="upload-instructions">
            <div className="arrow-gradient" />
            <div className="image-wrapper">
              <InstructionModalWrapper className="guide xbox">
                <ol>
                  <li className="guide-li">To record something cool that just happened (last 30 seconds), press the &apos;Xbox&apos; button on your controller and then press the &apos;X&apos; button (record that) OR for longer recordings (up to 10 minutes) of something about to happen, press the &apos;Xbox&apos; button then choose capture options using the &apos;View&apos; button 
                  <a onClick={(e) => window.open(e.target.href, '_blank')} target="_blank" href="https://www.youtube.com/watch?v=hMQsfjZGZWU"> https://www.youtube.com/watch?v=hMQsfjZGZWU</a></li>
                  <li className="guide-li">Install &apos;OneDrive&apos; to your Xbox One</li>
                  <li className="guide-li">Go to &apos;Broadcast & capture&apos;, click on &apos;Capture&apos; then click &apos;Manage captures&apos; </li>
                  <li className="guide-li">Select the clip you want to upload, then click the share button and choose &apos;OneDrive&apos; </li>
                  <li className="guide-li">Download OneDrive to your mobile phone (Android, IOS) and sign in to your Microsoft account (same email and password as your Xbox account)</li>
                  <li className="guide-li">Once you have accessed OneDrive,  select &apos;files&apos; and click &apos;videos&apos;. The gameplay you uploaded should appear in your &apos;Xbox Game DVR&apos; file</li>
                  <li className="guide-li">Once you&apos;ve opened up the video, click the options button (three circles on your screen) and press &apos;download&apos;</li>
                  <li className="guide-li">Find the video in your photos/screenshot gallery and share it to the YouTube app from your phone (you have to be signed in to YouTube on your phone first)</li>
                  <li className="guide-li">Title your video #enterthedarkzone and ensure that your privacy setting is set to public before you upload</li>
                </ol>
              </InstructionModalWrapper>
              <InstructionModalWrapper className="guide ps4">
                <ol>
                  <li className="guide-li">To record something cool that just happened, press the 'Share' button on your PS4 controller and select Upload Video Clip. For longer recordings of something about to happen, double tap the 'Share' button and then press 'Share' again at the end of the scene (max length 15 minutes).</li>
                  <li className="guide-li">Select the clip that you'd like to upload and choose YouTube</li>
                  <li className="guide-li">Trim the start or end point, add a title, description and tags</li>
                  <li className="guide-li">Confirm the privacy settings and that you're uploading to the correct channel</li>
                  <li className="guide-li">Select Share</li>
                  <li className="guide-li">If you need more instructions, visit <a onClick={(e) => window.open(e.target.href, '_blank')} href="https://support.playstation.com/s/article/Save-and-Share-PS4-Gameplay-Videos?language=en_US" target="_blank">this</a> link to go to the official Sony support page for sharing</li>
                  <li className="guide-li">Title your video #enterthedarkzone and ensure that your privacy setting is set to public before you upload</li>
                </ol>
              </InstructionModalWrapper>
              <div className="prizes" />
            </div>
            <img className="glow" alt="" src={withPrefix('middle_organe_glow.png')} />
            <video src={withPrefix('autovideo.mp4')} autoPlay muted playsInline loop />
          </div>
        )}
      </StyledSignupInstructions>
    )}
  />
);

SignupInstructions.propTypes = {
  team: PropTypes.string.isRequired,
  isUpload: PropTypes.bool,
};

SignupInstructions.defaultProps = {
  isUpload: false,
};

export default SignupInstructions;
