const calcFontVal = (minViewportWidth, maxViewportWidth, minFontSize, maxFontSize) => (
  `calc(${minFontSize}px + 
    (${maxFontSize} - ${minFontSize}) * 
    ((100vw - ${minViewportWidth}px) / 
    (${maxViewportWidth} - ${minViewportWidth})))`
);

const fluidType = (args) => {
  const minViewportWidth = args.minvw;
  const maxViewportWidth = args.maxvw;
  const minFontSize = args.minFont;
  const maxFontSize = args.maxFont;

  return `
    font-size: ${minFontSize}px;
    @media screen and (min-width: ${minViewportWidth}px) {
      font-size: ${calcFontVal(minViewportWidth, maxViewportWidth, minFontSize, maxFontSize)};
    }
    @media screen and (min-width: ${maxViewportWidth}px) {
      font-size: ${maxFontSize}px;
    }
  `;
};

export default fluidType;
