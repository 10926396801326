import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import StyledNavigation from './style';
import Wrapper from '../wrapper';

const Nav = () => (
  <StaticQuery
    query={graphql`
      query navImages {
        logo: file(relativePath: {eq: "main_logo.png"}) {
          childImageSharp {
            fluid(maxWidth: 400) {
              src
              sizes
              srcSet
              srcSetWebp
              aspectRatio
            }
          }
        }
        navBG: file(relativePath: {eq: "nav_bg.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 1280) {
              src
              sizes
              srcSet
              srcSetWebp
              aspectRatio
            }
          }
        }
        cta: file(relativePath: {eq: "CTA.png"}) {
          childImageSharp {
            fluid(maxWidth: 400) {
              src
              sizes
              srcSet
              srcSetWebp
              aspectRatio
            }
          }
        }
        gameLogo: file(relativePath: {eq: "division2_logo.png"}) {
          childImageSharp {
            fluid(maxWidth: 400) {
              src
              sizes
              srcSet
              srcSetWebp
              aspectRatio
            }
          }
        }
      }
    `}
    render={data => (
      <StyledNavigation>
        <Wrapper className="nav-wrapper">
          <a href="http://ubi.li/zkqv5" target="_blank" rel="noopener noreferrer">
            <Img
              className="nav-btn"
              fluid={data.gameLogo.childImageSharp.fluid}
            />
          </a>
          <Img
            className="nav-bg"
            fluid={data.navBG.childImageSharp.fluid}
          />
          <Img
            className="nav-logo"
            fluid={data.logo.childImageSharp.fluid}
          />
          <a href="http://ubi.li/zkqv5" target="_blank" rel="noopener noreferrer">
            <Img
              className="nav-btn"
              fluid={data.cta.childImageSharp.fluid}
            />
          </a>
        </Wrapper>
      </StyledNavigation>
    )}
  />
);

export default Nav;
