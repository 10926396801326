import styled from 'styled-components';

const StyledFooter = styled.footer`
  width: 100%;
  min-height: 14rem;
  background: #000;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  padding-top: 2rem;

  svg {
    margin-bottom: 1rem;
    width: 100px;
  }
  img {
    width: 40px;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
  p {
    max-width: 720px;
    text-align: center;
    font-size: 14px;
  }
`;

export default StyledFooter;
