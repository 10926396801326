/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import styled from 'styled-components';

const StyledUl = styled.ul`
  li {
    margin-bottom: 1rem;
    list-style-type: square;
  }
  strong {
    font-weight: 700;
  }
  @media only screen and (max-width: 768px) {
    li {
      font-size: 12px;
    }
  }

  @media only screen and (max-width: 340px) {
    li {
      font-size: 0.75rem;
    }
  }
`;

const Terms = () => (
  <StyledUl>
    <li>Competition is open to UK entrants only</li>
    <li>Entrants must be aged 18yrs or older</li>
    <li>Employees of Gamer Network and Ubisoft, or members of their family, are not permitted to enter</li>
    <li>The competition ends on 4th March 9am GMT</li>
    <li>The judge's decision is final</li>
    <li>By entering the competition, you agree for us to contact you via the email you submit</li>
    <li>Under no circumstances will the prize be swapped for cash or other consideration</li>
    <li>Details will only be used to contact the contestants. All other data will be deleted by Gamer Network immediately thereafter</li>
    <li>This competition is being organised and run in accordance with industry standards and the applicable law of England and Wales</li>
  </StyledUl>
);

export default Terms;
