import styled from 'styled-components';

const StyledParallaxSection = styled.section`
  width: 100%;
  height: 34rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: inset 0px 8px 24px 4px rgba(0,0,0,0.33);

  @media only screen and (max-width: 1024px) {
    height: 28rem;
  }

  .background {
    width: 100%;
    height: 100%;
    position: fixed !important;
    /* object-fit: cover; */
    top: 0;
    z-index: -1;
  }

  h2 {
    margin-bottom: 0;
    line-height: 1.1;
    text-transform: uppercase;
    font-weight: 400;
  }

  span {
    color: #ff7224;
    font-weight: 700;

    h2 {
      font-weight: 700;
    }
  }

  .parallax-cta {
    width: 220px;
    margin-top: 1rem;
  }
`;

export default StyledParallaxSection;
