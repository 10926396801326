import styled from 'styled-components';

const Wrapper = styled.div`
  display: block;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  ${props => (props.styles)};
`;

export default Wrapper;
