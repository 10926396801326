import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import StyledLayout from './style';

import Nav from '../Nav';

const Layout = ({ children }) => (
  <Fragment>
    <Nav />
    <StyledLayout>
      {children}
    </StyledLayout>
  </Fragment>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
