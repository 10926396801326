import styled from 'styled-components';
import { withPrefix } from 'gatsby';

const StyledSignupInstructions = styled.div`
  flex-basis: 70%; 
  position: relative;

  @media only screen and (max-width: 1024px) {
    padding-right: 0;
    order: 1;
  }

  h2 {
    @media only screen and (max-width: 1024px) {
      text-align: center;
    }
  }

  .arrow-down {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 999;
    width: 0;
    height: 0;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-bottom: 30px solid black;
    transform-origin: 0 0;
    transform: rotate(180deg) translateY(-60px) translateX(-50%);
    left: 50%;
  }

  .overview {
    position: relative;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 1024px) {
      text-align: center;
      height: auto;
      flex-direction: column;
      padding-top: 3rem;
      padding-left: 2rem;
      padding-right: 2rem;
    }

    h4 {
      position: relative;
      z-index: 1;
      color: #fff;
      flex-basis: 60%;
      margin-left: auto;
      margin-right: 2rem;
      text-align: center;
      font-weight: 700;

      @media only screen and (max-width: 1024px) {
        flex-basis: 100%;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 2rem;
      }
    }

    .upload-heading {
      font-size: 1.4rem;
      line-height: 1.25;

      span {
        color: #ff7224;
      }
    }

    .overview-bg {
      position: absolute !important;
      top: 0; left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .logo {
      width: 450px;
      margin-top: auto;

      @media only screen and (max-width: 1024px) {
        margin-bottom: 0;
        width: 100%;
        max-width: 450px;
        order: 2;
      }
    }
  }

  .instructions-wrapper {
    position: relative;
    width: 100%;
    /* min-height: 250px; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1.5rem 0 1.5rem 0;

    @media only screen and (max-width: 1024px) {
      padding-top: 1rem;
      padding-bottom: 0;
    }
    
    .overview-bg-bottom {
      position: absolute !important;
      top: 0; left: 0;
      width: 100%;
      height: 100%;
    }
    
    .instruction {
      position: relative;
      margin: 0;
      padding: 1rem;
      padding-top: 2rem;
      width: 33.333%;
      min-height: 250px;
      text-align: center;
      border-right: 2px dashed #c34400;
      padding-bottom: 2rem;
      font-weight: 700;

      &:nth-of-type(4) {
        border-right: 0;
        border-bottom: 0;

        @media only screen and (max-width: 1024px) {
          padding-top: 2rem;
        }
      }

      @media only screen and (max-width: 1024px) {
        flex-basis: 100%;
        border-right: 0;
        border-bottom: 2px dashed #000;
      }

      h3, h4 {
        text-transform: uppercase;
      }

      h4 {
        margin-bottom: 0;
        color: #fff;
        font-weight: 400;
      }

      h3 {
        margin-bottom: 0.75rem;
      }

      a {
        color: #fff;
        font-weight: 700;
      }

      .icon {
        width: 70px;
        margin: 0 auto;
        margin-bottom: 1rem;
      }

      .pre-order-btn {
        background: rgba(0,0,0,0.25);
        border: 1px solid #333;
        border-radius: 4px;
        color: #fff;
        text-transform: uppercase;
        padding: 0.33rem 1.5rem;
      }
    }
  }

  @keyframes gradient {
    0%{ 
      transform: scaleX(0);
    }
    100% {
      transform: scaleX(1);
    }
  }

  .upload-instructions {
    position: relative;
    width: 100%;
    overflow: hidden;

    &:after {
      position: absolute;
      top: 0; left: 0;
      content: '';
      width: 100%;
      height: 50%;
      /* background: linear-gradient(#FD6C3A, transparent); */
      box-shadow: 0px 50px 250px 0px #c34400;
      transform: scaleX(0);
      animation: gradient 750ms 1000ms ease forwards;

      @media only screen and (max-width: 1280px) {
        height: 100%;
        width: 50%;
      }
    }

    &:before {
      position: absolute;
      bottom: 0; left: 0;
      content: '';
      width: 100%;
      height: 50%;
      /* background: linear-gradient(#FD6C3A, transparent); */
      box-shadow: 0px -50px 250px 0px  #c34400;
      z-index: 1;
      transform: scaleX(0);
      animation: gradient 750ms 1000ms ease forwards;

      @media only screen and (max-width: 1280px) {
        height: 100%;
        width: 50%;
        right: 0;
        left: auto;
      }
    }

    .arrow-gradient {
      position: absolute;
      top: -40px; left: calc(50% - 12.5%);
      content: '';
      width: 25%;
      height: 75px;
      z-index: 1;
      background: radial-gradient(#fd6c3a, transparent);
      filter: blur(20px);
      border-radius: 50%;
    }

    @keyframes video {
      0%{ 
        filter: brightness(1);
      }
      100% {
        filter: brightness(0.25);
      }
    }

    video {
      position: absolute;
      top: -3px; left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      animation: video 1000ms 1000ms ease forwards;
    }


    .glow {
      position: absolute;
      top: 0; 
      left: 0;
      width: 100%;
      opacity: 0;
      z-index: 1;
    }

    .image-wrapper {
      position: relative;
      z-index: 2;
      width: 100%;
      padding: 2rem 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        width: calc(33% - 1rem);
        height: 300px;
      }

      @media only screen and (max-width: 1024px) {
        flex-direction: column;

        div {
          width: 300px;
        }
      }

      @keyframes card_in {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

      .guide {
        a {
          display: block;
          width: 100%;
          height: 100%;
          z-index: 1;
        }
      }

      .xbox {
        position: relative;
        background-image: url(${withPrefix('xbox_guide.png')});
        background-size: cover;
        background-position: center center;
        opacity: 0;
        animation: card_in 1000ms 1500ms ease forwards;

        &:after {
          position: absolute;
          top: 50%;
          right: -1.5rem;
          content: '';
          z-index: 1;
          width: 23px;
          height: 14px;
          background-image: url(${withPrefix('or.png')});

          @media only screen and (max-width: 1280px) {
            display: none;
          }
        }

        &:hover {
          filter: brightness(1.25);

          &:after {
            filter: brightness(0.75);
          }
        }
      }

      .ps4 {
        position: relative;
        background-image: url(${withPrefix('PS4_guide.png')});
        background-size: cover;
        background-position: center center;
        opacity: 0;
        animation: card_in 1000ms 1750ms ease forwards;

        &:hover {
          filter: brightness(1.25);

          &:after {
            filter: brightness(0.75);
          }
        }

        @media only screen and (max-width: 1280px) {
          margin-bottom: 2.25rem;
        }

        &:after {
          position: absolute;
          top: 50%;
          right: -1.8rem;
          content: '';
          z-index: 1;
          width: 36px;
          height: 14px;
          background-image: url(${withPrefix('and.png')});

          @media only screen and (max-width: 1280px) {
            display: none;
          }
        }
      }

      .prizes {
        background-image: url(${withPrefix('prizes.png')});
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        height: 232px;
        opacity: 0;
        animation: card_in 1000ms 2000ms ease forwards;
      }
    }
  }
`;

export default StyledSignupInstructions;
