/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

Modal.setAppElement('#___gatsby');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: 'rgba(0,0,0,0.8)',
    color: '#fff',
    border: '2px solid #F56A1F',
    maxWidth: 720,
    boxShadow: '0px 0px 40px 1px #F56A1F',
  },
};

class InstructionModalWrapper extends Component {
  state = {
    modalIsOpen: false,
  }

  openModal = (e) => {
    e.preventDefault();
    this.setState({ modalIsOpen: true });
  }

  closeModal = (e) => {
    e.stopPropagation();
    this.setState({ modalIsOpen: false });
  }

  render() {
    const { modalIsOpen } = this.state;
    const { children, className } = this.props;
    return (
      <div onClick={this.openModal} className={className}>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel="Example Modal"
          style={customStyles}
        >
          {children}
        </Modal>
      </div>
    );
  }
}

InstructionModalWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
};

export default InstructionModalWrapper;
