import styled from 'styled-components';

const StyledNavigation = styled.nav`
  position: relative;
  width: 100%;
  height: 100px;
  background: #f9f9f9;
  box-shadow: 1px 0px 4px 1px rgba(0,0,0,0.2);
  background: #000;
  z-index: 1;

  @media only screen and (max-width: 768px) {
    height: 60px;
  }

  .nav-wrapper {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 1rem;

    .nav-bg {
      position: absolute !important;
      top: 0; left: 0;
      width: 100%;
      height: 100%;
    }

    .nav-logo {
      margin-left: auto;
      margin-right: auto;
      width: 400px;
      max-width: 400px;
      transform: translateY(2rem);
      z-index: 1;

      @media only screen and (max-width: 768px) {
        width: 250px;
        max-width: 250px;
        transform: translateY(1.5rem);
      }
    }

    .nav-btn {
      position: relative;
      width: 200px;
      /* height: 30px; */
      z-index: 1;

      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
  }
`;

export default StyledNavigation;
