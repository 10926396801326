/* eslint indent: 0 */

import styled from 'styled-components';

import fluidType from '../../../utils/fluidType';

/*
  Modular Scales:
  + Mobile: 1.125
  + Desktop: 1.2
*/

const StyledLayout = styled.main`
  /* Headings */
  h1 {
    ${fluidType({
      minFont: 32.437,
      maxFont: 47.776,
      minvw: 320,
      maxvw: 1280,
    })};
  }

  h2 {
    ${fluidType({
      minFont: 28.833,
      maxFont: 39.813,
      minvw: 320,
      maxvw: 1280,
    })};
  }

  h3 {
    ${fluidType({
      minFont: 25.629,
      maxFont: 33.178,
      minvw: 320,
      maxvw: 1280,
    })};
  }

  h4 {
    ${fluidType({
      minFont: 22.781,
      maxFont: 27.648,
      minvw: 320,
      maxvw: 1280,
    })};
  }

  h5 {
    ${fluidType({
      minFont: 20.25,
      maxFont: 23.04,
      minvw: 320,
      maxvw: 1280,
    })};
  }

  h6 {
    ${fluidType({
      minFont: 18,
      maxFont: 19.2,
      minvw: 320,
      maxvw: 1280,
    })};
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    margin-bottom: 1rem;
    line-height: 1.2;
    font-family: 'Exo 2', sans-serif;
  }

  p, span, a, button {
    font-family: 'Exo 2', sans-serif;
  }

  p {
    font-size: 1rem;
    line-height: 1.4;
    margin-bottom: 1rem;
  }
`;

export default StyledLayout;
