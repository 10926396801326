import React, { Component, Fragment } from 'react';
import Modal from 'react-modal';

import Terms from '../terms';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

Modal.setAppElement('#___gatsby');

class TermsOpen extends Component {
  state = {
    modalIsOpen: false,
  }

  openModal = (e) => {
    e.preventDefault();
    this.setState({
      modalIsOpen: true,
    });
  }

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
  }

  render() {
    const { modalIsOpen } = this.state;
    return (
      <Fragment>
        <a onClick={this.openModal} href="/">Terms And Conditions</a>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Terms And Conditions"
        >
          <Terms />
        </Modal>
      </Fragment>
    );
  }
}

export default TermsOpen;
