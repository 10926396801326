import React from 'react';
import PropTypes from 'prop-types';

import Img from 'gatsby-image';

import StyledParallaxSection from './style';

const ParallaxSection = ({ background, children }) => (
  <StyledParallaxSection background={background}>
    <Img className="background" fluid={background} />
    {children}
  </StyledParallaxSection>
);

ParallaxSection.propTypes = {
  children: PropTypes.node.isRequired,
  background: PropTypes.shape({}).isRequired,
};

export default ParallaxSection;
